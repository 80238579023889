@tailwind base;
@tailwind components;
@tailwind utilities;

/* Smooth scrolling for all scrolling containers */
html {
  scroll-behavior: smooth;
}

/* Ensure the root element takes full height */
html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
